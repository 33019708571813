import React, { useEffect, useState, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import car_insurance from '../../assets/images/car_insurance.jpg';
import third_party from '../../assets/images/Third_Party_Liability_Only_Cover.avif';
import unisured_motorist from '../../assets/images/Uninsured_Motorist_Protection.avif';
import own_car_damage from '../../assets/images/Own_Damage_Cover.avif';
import personal_accident from '../../assets/images/Personal_Accident_Cover.webp';
import comprehensive_car from '../../assets/images/Comprehensive_Car_Insurance.jpg';
import { Link } from 'react-router-dom';

function Car_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: third_party,
            title: 'Third-Party Liability Only Cover',
            description: 'Covers legal and financial liabilities for damages or injuries caused to third parties by your vehicle.'
        },
        {
            img: unisured_motorist,
            title: 'Uninsured Motorist Protection',
            description: 'Provides coverage for damages or injuries caused by an uninsured or underinsured driver.'
        },
        {
            img: own_car_damage,
            title: 'Own Damage (OD) Cover',
            description: 'Protects your vehicle from damages caused by accidents, theft, fire, or natural disasters.'
        },
        {
            img: personal_accident,
            title: 'Personal Accident Cover',
            description: 'Provides financial compensation for medical expenses, injuries, or fatalities resulting from car accidents.'
        },
        {
            img: comprehensive_car,
            title: 'Comprehensive Car Insurance',
            description: 'Offers extensive coverage for damages to your car, third-party liabilities, theft, and natural calamities.'
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <h1 className="home_insurance-main-title">Car Insurance</h1>
                <p className="home_insurance-subtitle">Protect Your Vehicle with Comprehensive Car Insurance Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Car insurance provides financial protection for your vehicle, covering damages, accidents, theft, and third-party liabilities.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Car insurance plans are designed to be cost-effective while offering essential coverage for your vehicle's protection.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Policies with straightforward terms and easy claim processes ensure hassle-free coverage for car owners.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Customize your car insurance with additional coverage options like roadside assistance and zero depreciation.</p>
                        </div>
                    </div>
                </div>

                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Car Insurance</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <div
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title">Benefits of Car Insurance</h2>
                    <div className="home_insurance-benefits-inner-section">
                        <div>
                            <img src={car_insurance} alt="Benefits of Car Insurance" />
                        </div>
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Car insurance provides financial security against unforeseen expenses due to accidents, theft, or natural disasters.</li>
                                <li>It ensures compliance with legal requirements, avoiding penalties for driving without insurance.</li>
                                <li>With 24/7 assistance, you can avail of roadside support and quick claim processing when needed.</li>
                                <li>Additional coverage options like engine protection and zero depreciation offer tailored benefits for your vehicle.</li>
                                <li>Enjoy peace of mind knowing your car is covered for various risks, allowing you to drive worry-free.</li>
                                <li>Discover more advantages by booking a free appointment to select the best car insurance plan for your needs.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Car_Insurance;
