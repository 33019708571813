import React, { useState, useEffect } from "react";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import axios from "axios";
import adminlogoo from "../../assets/images/upholic.newlogo.png";
import "./Life_insurance_dashboard.css";

function Life_insurance_dashboard() {
  const [resumeAccordionOpen, setResumeAccordionOpen] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [nomineeData, setNomineeData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedNominee, setSelectedNominee] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(0); // State to store the customer count
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [exitCount, setExitCount] = useState(0);
  const [error, setError] = useState(null);

  // Toggle resume accordion
  const toggleResumeAccordion = () => {
    setResumeAccordionOpen(!resumeAccordionOpen);
  };

  // Function to fetch total customer count from the API
  const fetchTotalCustomers = async () => {
    try {
      // Make a GET request to your PHP endpoint
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/life_insurance_main_head.php"
      );

      console.log(response);
      // Check if the response contains total_customers and update state
      if (response.data && response.data.total_customers !== undefined) {
        setTotalCustomers(response.data.total_customers); // Update state with the count
      }
    } catch (error) {
      console.error("Error fetching total customers:", error);
    }
  };

  // Fetch total customers when the component mounts
  useEffect(() => {
    fetchTotalCustomers();
  }, []);

  // Fetch gender statistics from the API
  const fetchGenderStats = async () => {
    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/life_insurance_main_head.php"
      );
      if (response.data) {
        // Set the male and female counts from the response
        setMaleCount(response.data.male_count);
        setFemaleCount(response.data.female_count);
      }
    } catch (error) {
      console.error("Error fetching gender stats:", error);
    }
  };

  useEffect(() => {
    fetchGenderStats();
  }, []);

  // Use useEffect hook to fetch data on component mount
  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        // Sending the GET request using Axios with await
        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/life_insurance_main_head.php"
        );

        // Check if the response contains an error
        if (response.data.error) {
          setError(response.data.error);
        } else {
          // Set the fetched data into state
          setActiveCount(response.data.active_count);
          setExitCount(response.data.exit_count);
        }
      } catch (err) {
        // Handle error if the request fails
        setError("Error fetching data");
        console.error(err);
      }
    };

    // Call the fetch function
    fetchInsuranceData();
  }, []); // Empty dependency array to only run once

  // Fetch KYC data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/getLifeInsuranceKycData.php"
        );
        setKycData(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };
    fetchData();
  }, []);

  // Fetch life insurance plan data
  useEffect(() => {
    const fetchLifeInsuranceData = async () => {
      try {
        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/getLifeInsurancePlans.php"
        );
        setPlanData(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };
    fetchLifeInsuranceData();
  }, []);

  // Fetch life insurance nominee data
  useEffect(() => {
    const fetchLifeInsuranceNomineeData = async () => {
      try {
        const response = await axios.get(
          "https://www.upholic.in/Upholic_Api/getLifeInsuranceNominee.php"
        );
        setNomineeData(response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };
    fetchLifeInsuranceNomineeData();
  }, []);

  // Function to open the popup and set the selected user
  const openPopup = (user) => {
    setSelectedUser(user);

    const plan = planData.find((plan) => plan.user_id === user.user_id);
    setSelectedPlan(plan || null);

    const nominee = nomineeData.find(
      (nominee) => nominee.user_id === user.user_id
    );
    setSelectedNominee(nominee || null);

    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedUser(null);
    setSelectedPlan(null);
    setSelectedNominee(null);
  };

  return (
    <>
      <Admin_sidebar_links />
      <div className="man-man-div">
        <div className="deposite-header area">
          <ul className="circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="deposite-container">
            <div className="deposite-logo-img">
              <img
                src={adminlogoo}
                alt="Logo 1"
                className="deposite-image-logo"
              />
            </div>
            <div className="deposite-search-bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="deposite-logo2-bar">
              <h1 className="deposite-page-headingg">Life Insurance</h1>
            </div>
          </div>

          {/* ======================= Cards ================== */}
          <div className="deposite-cardBox">
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{totalCustomers}</div>
                <div className="deposite-cardName">Total Customer</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{maleCount}</div>
                <div className="deposite-cardName">Male</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="man-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{femaleCount}</div>
                <div className="deposite-cardName">Female</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="woman-outline"></ion-icon>
              </div>
            </div>

            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{activeCount}</div>
                <div className="deposite-cardName">Active</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="pulse-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">{exitCount}</div>
                <div className="deposite-cardName">Exit</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="exit-outline"></ion-icon>
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
        </div>

        <div className="pms_dashboard_areaa">
          <ul className="pms_dashboard_circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="Pms_dashboard_card">
            <div
              className="Pms_dashboard_section_title"
              onClick={toggleResumeAccordion}
              style={{ cursor: "pointer" }}
            >
              Life Insurance Kyc
            </div>
            {resumeAccordionOpen && (
              <div className="table-wrapper">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Full Name</th>
                      <th>Date Of Birth</th>
                      <th>Phone Number</th>
                      <th>Annual Income</th>
                      <th>Occupation</th>
                      <th>E-mail</th>
                      <th>Education</th>
                      <th>Marital Status</th>
                      <th>State</th>
                      <th>City</th>
                      <th>Pin Code</th>
                      <th>Gender</th>
                      <th>Adhaar Card</th>
                      <th>Pan Card</th>
                      <th>Nationality</th>
                      <th>Tobacco/Nicotine/Alcohol</th>
                    </tr>
                  </thead>
                  <tbody>
                    {kycData.length > 0 ? (
                      kycData.map((likyc, index) => (
                        <tr key={index}>
                          <td
                            style={{ cursor: "pointer", color: "gold" }}
                            onClick={() => openPopup(likyc)}
                          >
                            {likyc.user_id}
                          </td>
                          <td
                            style={{ cursor: "pointer", color: "gold" }}
                            onClick={() => openPopup(likyc)}
                          >
                            {likyc.full_name}
                          </td>
                          <td>{likyc.date_of_birth}</td>
                          <td>{likyc.phone_number}</td>
                          <td>{likyc.annual_income}</td>
                          <td>{likyc.occupation}</td>
                          <td>{likyc.email}</td>
                          <td>{likyc.education}</td>
                          <td>{likyc.marital_status}</td>
                          <td>{likyc.state}</td>
                          <td>{likyc.city}</td>
                          <td>{likyc.pin_code}</td>
                          <td>{likyc.gender}</td>
                          <td>{likyc.adhaar_card}</td>
                          <td>{likyc.pan_card}</td>
                          <td>{likyc.nationality}</td>
                          <td>{likyc.tobacco_nicotine_alcohol}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="17">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* Popup for displaying user details */}
        {isPopupOpen && selectedUser && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3 className="LI_Details_popup_heading">
                Life Insurance Details
              </h3>

              {/* Life Insurance KYC Section */}
              <div className="section">
                <h4>Life Insurance KYC</h4>
                <div className="row">
                  <div className="column">
                    <p>
                      <strong>User ID:</strong> {selectedUser.user_id}
                    </p>
                    <p>
                      <strong>Full Name:</strong> {selectedUser.full_name}
                    </p>
                    <p>
                      <strong>Date of Birth:</strong>{" "}
                      {selectedUser.date_of_birth}
                    </p>
                    <p>
                      <strong>Phone Number:</strong> {selectedUser.phone_number}
                    </p>
                    <p>
                      <strong>Annual Income:</strong>{" "}
                      {selectedUser.annual_income}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>Occupation:</strong> {selectedUser.occupation}
                    </p>
                    <p>
                      <strong>Email:</strong> {selectedUser.email}
                    </p>
                    <p>
                      <strong>Education:</strong> {selectedUser.education}
                    </p>
                    <p>
                      <strong>Marital Status:</strong>{" "}
                      {selectedUser.marital_status}
                    </p>
                    <p>
                      <strong>State:</strong> {selectedUser.state}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>City:</strong> {selectedUser.city}
                    </p>
                    <p>
                      <strong>Pin Code:</strong> {selectedUser.pin_code}
                    </p>
                    <p>
                      <strong>Gender:</strong> {selectedUser.gender}
                    </p>
                    <p>
                      <strong>Aadhaar Card:</strong> {selectedUser.adhaar_card}
                    </p>
                    <p>
                      <strong>Pan Card:</strong> {selectedUser.pan_card}
                    </p>
                  </div>
                </div>
              </div>

              {/* Life Insurance Plan Section */}
              <div className="section">
                <h4>Life Insurance Plan</h4>
                <div className="row">
                  <div className="column">
                    <p>
                      <strong>Policy Name Holder:</strong>{" "}
                      {selectedPlan ? selectedPlan.Policy_name_holder : "N/A"}
                    </p>
                    <p>
                      <strong>Policy Company Name:</strong>{" "}
                      {selectedPlan ? selectedPlan.Policy_company_name : "N/A"}
                    </p>
                    <p>
                      <strong>Life Cover:</strong>{" "}
                      {selectedPlan ? selectedPlan.Life_Cover : "N/A"}
                    </p>
                    <p>
                      <strong>Exit:</strong>{" "}
                      {selectedPlan ? selectedPlan.exit : "N/A"}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>Premium Amount:</strong>{" "}
                      {selectedPlan ? selectedPlan.Premium_amount : "N/A"}
                    </p>
                    <p>
                      <strong>Premium Frequency:</strong>{" "}
                      {selectedPlan ? selectedPlan.Premium_Frequency : "N/A"}
                    </p>
                    <p>
                      <strong>Application Date:</strong>{" "}
                      {selectedPlan
                        ? new Date(
                            selectedPlan.Application_Date
                          ).toLocaleDateString()
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Active:</strong>{" "}
                      {selectedPlan ? selectedPlan.active : "N/A"}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>Application Number:</strong>{" "}
                      {selectedPlan ? selectedPlan.Application_Number : "N/A"}
                    </p>
                    <p>
                      <strong>Coverage Period:</strong>{" "}
                      {selectedPlan ? selectedPlan.I_will_be_coverd_for : "N/A"}
                    </p>
                    <p>
                      <strong>Premium Payment Period:</strong>{" "}
                      {selectedPlan ? selectedPlan.I_will_pay_premium : "N/A"}
                    </p>
                    <p>
                      <strong>Life Cover You Pay:</strong>{" "}
                      {selectedPlan ? selectedPlan.Life_Cover_You_Pay : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Life Insurance Nominee Section */}
              <div className="section">
                <h4>Life Insurance Nominee</h4>
                <div className="row">
                  <div className="column">
                    <p>
                      <strong>Nominee Name:</strong>{" "}
                      {selectedNominee && selectedNominee.nominee_name
                        ? selectedNominee.nominee_name
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Nominee Relationship:</strong>{" "}
                      {selectedNominee && selectedNominee.nominee_relationship
                        ? selectedNominee.nominee_relationship
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Gender:</strong>{" "}
                      {selectedNominee && selectedNominee.gender
                        ? selectedNominee.gender
                        : "N/A"}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>Date of Birth:</strong>{" "}
                      {selectedNominee && selectedNominee.date_of_birth
                        ? new Date(
                            selectedNominee.date_of_birth
                          ).toLocaleDateString()
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Phone Number:</strong>{" "}
                      {selectedNominee && selectedNominee.phone_number
                        ? selectedNominee.phone_number
                        : "N/A"}
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>Aadhaar Card:</strong>{" "}
                      {selectedNominee && selectedNominee.adhaar_card ? (
                        <a
                          className="pancardhref"
                          href={`https://www.upholic.in/Upholic_Api/${selectedNominee.adhaar_card}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Aadhaar Card
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                    <p>
                      <strong>Pan Card:</strong>{" "}
                      {selectedNominee && selectedNominee.pan_card ? (
                        <a
                          className="pancardhref"
                          href={`https://www.upholic.in/Upholic_Api/${selectedNominee.pan_card}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Pan Card
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <button className="libuttonclose" onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Life_insurance_dashboard;
