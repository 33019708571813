import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import adminlogoo from "../../assets/images/upholic.newlogo.png";

function HR_resume() {
  const [resumeAccordionOpen, setResumeAccordionOpen] = useState(false); // State for resume details accordion
  const [resumeDataStats, setResumeDataStats] = useState([]); // State for resume details

  const [resumeData, setResumeData] = useState({
    totalResumes: 0,
    selectedCount: 0,
    rejectedCount: 0,
  });

  // Fetch summary resume data
  const fetchResumeData = async () => {
    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/fetch_reume_details.php"
      );
      const { totalResumes, selectedCount, rejectedCount } = response.data;

      setResumeData({
        totalResumes,
        selectedCount,
        rejectedCount,
      });
    } catch (error) {
      console.error("There was an error fetching resume data!", error);
    }
  };

  // Fetch detailed resume data
  const fetchResumeDetails = async () => {
    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/getResumeData.php"
      );
      setResumeDataStats(response.data);
    } catch (error) {
      console.error("There was an error fetching the resume details!", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchResumeData();
    fetchResumeDetails();
  }, []);

  // Toggle resume accordion
  const toggleResumeAccordion = () => {
    setResumeAccordionOpen(!resumeAccordionOpen);
  };

  return (
    <>
      <Admin_sidebar_links />
      <div className="man-man-div">
        <div className="deposite-header area">
          <ul className="circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="deposite-container">
            <div className="deposite-logo-img">
              <img
                src={adminlogoo}
                alt="Logo 1"
                className="deposite-image-logo"
              />
            </div>
            <div className="deposite-search-bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="deposite-logo2-bar">
              <h1 className="deposite-page-headingg">Resume</h1>
            </div>
          </div>

          {/* ======================= Cards ================== */}
          <div className="deposite-cardBox">
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">
                  {resumeData.totalResumes}
                </div>
                <div className="deposite-cardName">Total Resumes</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="documents-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">
                  {resumeData.selectedCount}
                </div>
                <div className="deposite-cardName">Selected</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
              </div>
            </div>
            <div className="deposite-card">
              <div>
                <div className="deposite-numbers">
                  {resumeData.rejectedCount}
                </div>
                <div className="deposite-cardName">Rejected</div>
              </div>
              <div className="deposite-iconBx">
                <ion-icon name="close-circle-outline"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div className="pms_dashboard_areaa">
          <ul className="pms_dashboard_circles">
            {Array(10)
              .fill()
              .map((_, index) => (
                <li key={index}></li>
              ))}
          </ul>
          <div className="Pms_dashboard_card">
            <div
              className="Pms_dashboard_section_title"
              onClick={toggleResumeAccordion}
              style={{ cursor: "pointer" }}
            >
              Resume Details
            </div>
            {resumeAccordionOpen && (
              <div className="table-wrapper">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th className="custom-table-header">First Name</th>
                      <th className="custom-table-header">Last Name</th>
                      <th className="custom-table-header">Gender</th>
                      <th className="custom-table-header">Date Of Birth</th>
                      <th className="custom-table-header">Blood Group</th>
                      <th className="custom-table-header">Marital Status</th>
                      <th className="custom-table-header">Email</th>
                      <th className="custom-table-header">Phone Number</th>
                      <th className="custom-table-header">Address</th>
                      <th className="custom-table-header">Country</th>
                      <th className="custom-table-header">State</th>
                      <th className="custom-table-header">City</th>
                      <th className="custom-table-header">Zip</th>
                      <th className="custom-table-header">Education</th>
                      <th className="custom-table-header">Project</th>
                      <th className="custom-table-header">Skill</th>
                      <th className="custom-table-header">Resume</th>
                      <th className="custom-table-header">
                        Reason Select/Reject
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resumeDataStats.length > 0 ? (
                      resumeDataStats.map((resume, index) => (
                        <tr key={index}>
                          <td>{resume.first_name}</td>
                          <td>{resume.last_name}</td>
                          <td>{resume.gender}</td>
                          <td>{resume.dob}</td>
                          <td>{resume.bloodgroup}</td>
                          <td>{resume.marital_status}</td>
                          <td>{resume.email}</td>
                          <td>{resume.phone_number}</td>
                          <td>{resume.address}</td>
                          <td>{resume.country}</td>
                          <td>{resume.state}</td>
                          <td>{resume.city}</td>
                          <td>{resume.zip}</td>
                          <td>{resume.education}</td>
                          <td>{resume.project}</td>
                          <td>{resume.skill}</td>
                          <td>{resume.resume}</td>
                          <td>{resume.reason}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="18">No resume data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HR_resume;
