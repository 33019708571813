import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Document_employee.css";

function DocumentEmployee() {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: "",
    employee_first_name: "",
    employee_last_name: "",
    date_of_birth: "",
    role_designation: "",
    phone_number: "",
    employeeType: "",
    employeeGender: "",
    Department: "",
    office: "",
    employeeStatus: "",
    joiningDate: "",
    lastDate: "",
    bloodGroup: "", // Add blood group here
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false); // New state to toggle between insert and update

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Validation rules
    if (name === "employee_first_name" || name === "employee_last_name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setErrorMessage("Names can only contain letters and spaces.");
        return;
      }
    }

    if (name === "phone_number") {
      if (!/^\d*$/.test(value)) {
        setErrorMessage("Phone number can only contain digits.");
        return;
      }
      if (value.length > 10) {
        setErrorMessage("Phone number cannot exceed 10 digits.");
        return;
      }
    }

    // Clear error message if validation passes
    setErrorMessage("");

    // Check if the employee_id is cleared and reset all fields
    if (name === "employee_id" && value.trim() === "") {
      setFormData({
        employee_id: "",
        employee_first_name: "",
        employee_last_name: "",
        date_of_birth: "",
        role_designation: "",
        phone_number: "",
        employeeType: "",
        employeeGender: "",
        Department: "",
        office: "",
        employeeStatus: "",
        joiningDate: "",
        lastDate: "",
        bloodGroup: "",
      });
    } else {
      // Update the form field data for other fields
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    // Automatically fetch employee data when employee_id or phone_number is entered
    if (name === "employee_id" || name === "phone_number") {
      if (value.trim() !== "") {
        try {
          const response = await axios.post(
            "https://www.upholic.in/Upholic_Api/insert_employee.php",
            {
              action: "fetch",
              employee_id: name === "employee_id" ? value : "",
              phone_number: name === "phone_number" ? value : "",
            }
          );

          if (response.data.success) {
            const backendData = response.data.employee;

            // Map backend field names to frontend `formData` keys
            setFormData({
              employee_id: backendData.employee_id || "",
              employee_first_name: backendData.first_name || "",
              employee_last_name: backendData.last_name || "",
              date_of_birth: backendData.dob || "",
              role_designation: backendData.designation || "",
              phone_number: backendData.phone_number || "",
              employeeType: backendData.employee_type || "",
              employeeGender: backendData.gender || "",
              Department: backendData.department || "",
              office: backendData.office || "",
              employeeStatus: backendData.status || "",
              joiningDate: backendData.joining_date || "",
              lastDate:
                backendData.last_date !== "0000-00-00"
                  ? backendData.last_date
                  : "",
              bloodGroup: backendData.blood_group || "", // Add blood_group here
            });

            setIsUpdate(true); // Switch to update mode if data exists
            setSuccessMessage("Employee data fetched successfully!");
            setErrorMessage(""); // Clear previous errors
          } else {
            setErrorMessage(response.data.message || "Employee not found.");
            setSuccessMessage(""); // Clear success message
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
          setErrorMessage("Failed to fetch employee data. Please try again.");
        }
      }
    }
  };

  const fetchNextEmployeeId = async () => {
    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insert_employee.php",
        { action: "fetch_next_employee_id" }
      );
      console.log(response);
      if (response.data.next_employee_id) {
        setFormData({
          ...formData,
          employee_id: response.data.next_employee_id,
        });
      }
    } catch (error) {
      console.error("Error fetching next employee ID:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate phone number length before submitting
    if (formData.phone_number.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    const action = isUpdate ? "update" : "insert"; // Determine action based on state
    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insert_employee.php",
        {
          action,
          ...formData,
        }
      );
      console.log(response);

      if (response.data.message) {
        setSuccessMessage(response.data.message);
        setErrorMessage("");

        // Clear all fields after successful insertion
        if (!isUpdate) {
          setFormData({
            employee_id: "",
            employee_first_name: "",
            employee_last_name: "",
            date_of_birth: "",
            role_designation: "",
            phone_number: "",
            employeeType: "",
            employeeGender: "",
            Department: "",
            office: "",
            employeeStatus: "",
            joiningDate: "",
            lastDate: "",
            bloodGroup: "",
          });
        }
      } else {
        setSuccessMessage(
          isUpdate
            ? "Data updated successfully!"
            : "Data inserted successfully!"
        );
      }
    } catch (error) {
      console.error(`Error during ${action} operation:`, error);
      setErrorMessage(`Failed to ${action} data. Please try again.`);
    }
  };

  useEffect(() => {
    // Fetch next employee ID when component is mounted
    fetchNextEmployeeId();
  }, []);

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            Employee Data Upload
          </div>
          {accordionOpen && (
            <>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <form
                className="Pms_insert_user_kyc-form"
                onSubmit={handleSubmit}
              >
                <div className="Pms_insert_user_kyc-row">
                  <label>Employee ID:</label>
                  <input
                    type="text"
                    name="employee_id"
                    value={formData.employee_id}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Employee First Name:</label>
                  <input
                    type="text"
                    name="employee_first_name"
                    value={formData.employee_first_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Employee Last Name:</label>
                  <input
                    type="text"
                    name="employee_last_name"
                    value={formData.employee_last_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Date Of Birth:</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Designation:</label>
                  <select
                    name="role_designation"
                    value={formData.role_designation}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    {/* <!-- Executive Leadership --> */}
                    <optgroup label="Executive Leadership">
                      <option value="CEO">Chief Executive Officer (CEO)</option>
                      <option value="COO">Chief Operating Officer (COO)</option>
                      <option value="CFO">Chief Financial Officer (CFO)</option>
                      <option value="CTO">
                        Chief Technology Officer (CTO)
                      </option>
                      <option value="CIO">
                        Chief Information Officer (CIO)
                      </option>
                      <option value="CMO">Chief Marketing Officer (CMO)</option>
                      <option value="CHRO">
                        Chief Human Resources Officer (CHRO)
                      </option>
                      <option value="CPO">Chief Product Officer (CPO)</option>
                      <option value="CLO">Chief Legal Officer (CLO)</option>
                      <option value="CCO">Chief Customer Officer (CCO)</option>
                    </optgroup>

                    {/* <!-- Senior Management --> */}
                    <optgroup label="Senior Management">
                      <option value="vp_operations">
                        Vice President of Operations
                      </option>
                      <option value="vp_marketing">
                        Vice President of Marketing
                      </option>
                      <option value="vp_sales">Vice President of Sales</option>
                      <option value="vp_engineering">
                        Vice President of Engineering
                      </option>
                      <option value="director_finance">
                        Director of Finance
                      </option>
                      <option value="director_technology">
                        Director of Technology
                      </option>
                      <option value="director_customer_success">
                        Director of Customer Success
                      </option>
                    </optgroup>

                    {/* <!-- Middle Management --> */}
                    <optgroup label="Middle Management">
                      <option value="project_manager">Project Manager</option>
                      <option value="operations_manager">
                        Operations Manager
                      </option>
                      <option value="marketing_manager">
                        Marketing Manager
                      </option>
                      <option value="hr_manager">HR Manager</option>
                      <option value="assistant_manager">
                        Assistant Manager
                      </option>
                      <option value="team_lead">Team Lead</option>
                    </optgroup>

                    {/* <!-- Specialists and Individual Contributors --> */}
                    <optgroup label="Specialists and Individual Contributors">
                      <option value="senior_software_developer">
                        Senior Software Developer
                      </option>
                      <option value="senior_data_analyst">
                        Senior Data Analyst
                      </option>
                      <option value="software_developer">
                        Software Developer
                      </option>
                      <option value="data_analyst">Data Analyst</option>
                      <option value="ux_designer">UX Designer</option>
                      <option value="content_specialist">
                        Content Specialist
                      </option>
                    </optgroup>

                    {/* <!-- Entry-Level Roles --> */}
                    <optgroup label="Entry-Level Roles">
                      <option value="marketing_associate">
                        Marketing Associate
                      </option>
                      <option value="hr_associate">HR Associate</option>
                      <option value="sales_associate">Sales Associate</option>
                      <option value="trainee">Trainee</option>
                      <option value="intern">Intern</option>
                    </optgroup>

                    {/* <!-- Sales Designations --> */}
                    <optgroup label="Sales Designations">
                      <option value="sales_executive">Sales Executive</option>
                      <option value="sales_manager">Sales Manager</option>
                      <option value="senior_sales_manager">
                        Senior Sales Manager
                      </option>
                      <option value="relationship_manager">
                        Relationship Manager
                      </option>
                      <option value="senior_relationship_manager">
                        Senior Relationship Manager
                      </option>
                      <option value="area_head">Area Head</option>
                      <option value="senior_area_head">Senior Area Head</option>
                      <option value="cluster_head">Cluster Head</option>
                      <option value="senior_cluster_head">
                        Senior Cluster Head
                      </option>
                      <option value="zonal_manager">Zonal Manager</option>
                      <option value="sales_head">Sales Head</option>
                      <option value="regional_sales_manager">
                        Regional Sales Manager
                      </option>
                      <option value="national_sales_manager">
                        National Sales Manager
                      </option>
                      <option value="business_development_manager">
                        Business Development Manager
                      </option>
                      <option value="key_account_manager">
                        Key Account Manager
                      </option>
                      <option value="territory_sales_manager">
                        Territory Sales Manager
                      </option>
                    </optgroup>

                    {/* <!-- Support and Operational Staff --> */}
                    <optgroup label="Support and Operational Staff">
                      <option value="executive_assistant">
                        Executive Assistant
                      </option>
                      <option value="office_administrator">
                        Office Administrator
                      </option>
                      <option value="customer_support_representative">
                        Customer Support Representative
                      </option>
                      <option value="technical_support_specialist">
                        Technical Support Specialist
                      </option>
                      <option value="technician">Technician</option>
                      <option value="field_sales_representative">
                        Field Sales Representative
                      </option>
                      <option value="data_entry_operator">
                        Data Entry Operator
                      </option>
                      <option value="receptionist">Receptionist</option>
                    </optgroup>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phone_number" // Match the key in formData
                    value={formData.phone_number}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Employee Type:</label>
                  <select
                    name="employeeType"
                    value={formData.employeeType}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="part-time">Part-Time</option>
                    <option value="full-time">Full-Time</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Contractual">Contractual</option>
                    <option value="Intern">Intern</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Gender:</label>
                  <select
                    name="employeeGender"
                    value={formData.employeeGender}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Blood Group:</label>
                  <select
                    name="bloodGroup"
                    value={formData.bloodGroup}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Department:</label>
                  <select
                    name="Department"
                    value={formData.Department}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="HR">HR</option>
                    <option value="IT">IT</option>
                    <option value="Sales">Sales</option>
                    <option value="Operations">Operations</option>
                    <option value="Digital Marketing">Degital Marketing</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Office:</label>
                  <select
                    name="office"
                    value={formData.office}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="Thane">Thane</option>
                    <option value="Bhopal">Bhopal</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Employee Status:</label>
                  <select
                    name="employeeStatus"
                    value={formData.employeeStatus}
                    onChange={handleChange}
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Joining Date:</label>
                  <input
                    type="date"
                    name="joiningDate"
                    value={formData.joiningDate}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Last Date:</label>
                  <input
                    type="date"
                    name="lastDate"
                    value={formData.lastDate}
                    onChange={handleChange}
                  />
                </div>

                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    {isUpdate ? "Update" : "Insert"}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DocumentEmployee;
