import React from 'react'
import Life_Insurance_Type from '../life_Insurance_type/Life_Insurance_Type'
import Life_Insurance_Hero from '../life_Insurance_hero/Life_Insurance_Hero'
import Navbar from '../../pages/navbar/Navbar'
// import Life_Insurance_Card from '../life_Insurance_card/Life_Insurance_Card'
import Footer from '../../pages/footer/Footer'
import Copyright from '../../pages/copyright/Copyright'
import Fact from '../../pages/fact/Fact'
import Life_Insurance_Login from '../life_Insurance_login/Life_Insurance_Login'
// import Life_Insurance_Contact from '../life_Insurance_contact/Life_Insurance_Contact'

function Life_Insurance_Home() {
  return (
    <>
    <Navbar/>
    <Life_Insurance_Hero/>
    <Life_Insurance_Type/>
    <Life_Insurance_Login/>
    <Fact/>
    {/* <Life_Insurance_Card/> */}
    {/* <Life_Insurance_Contact/> */}
    <Footer/>
    <Copyright/>
    </>
  )
}

export default Life_Insurance_Home