import React, {useState} from 'react';
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import Document_Employee from '../document_employee/Document_Employee';
// import Document_resume from '../document_resume/Document_resume';
import Employee_kyc from '../employee_kyc/Employee_kyc';
import Document_insert_resume from '../document_insert_resume/Document_insert_resume';

function Document_entry() {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
      console.log("Accordion toggled"); // Add this line to verify the function is called
      setAccordionOpen(!accordionOpen);
    };
  return (
    <>
     <Admin_sidebar_links />
      <div className="pms_dashboard_area">
        {/* UL List */}
        <ul className="pms_dashboard_circles">
          {Array(10)
            .fill()
            .map((_, index) => (
              <li key={index}></li>
            ))}
        </ul>
        {/* Accordion Card */}
        <div className="Pms_dashboard_card">
          <div
            className="Pms_dashboard_section_title"
            onClick={toggleAccordion}
          >
            Document Entry
          </div>
          {accordionOpen && (
            <div className="accordion-content">
              <Document_Employee/>
              <Employee_kyc/>
              <Document_insert_resume/>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Document_entry;