import React, { useEffect } from "react";
import "./Our_Services.scss";
import AOS from "aos";
import "aos/dist/aos.css";

function Our_Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Allow animation to repeat every time on scroll
      mirror: true, // Repeat animation when scrolling up
    });
  }, []);

  return (
    <>
      <section className="our-services">
        <div className="container">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-md-6 pt-3" data-aos="fade-right">
              <small className="text-uppercase about-us">About Us</small>
              <h1 className="h2 mb-4 about-company" data-aos="fade-up">
                About Our <span className="ser-highlighted-text">Company</span>
              </h1>
              <p
                className="text-secondary about-description"
                data-aos="fade-up"
              >
                Our team comprises seasoned professionals with a wealth of
                experience in the financial industry. Clients choose us for our
                deep knowledge and proven track record of delivering results.
                <br />
                Our mission is to empower our clients to maximize the potential
                of their financial resources and achieve their long-term wealth
                goals. Through personalized financial planning, prudent
                investment strategies, and ongoing guidance, we provide the
                tools and expertise necessary to cultivate and protect our
                clients' assets.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-md-6 text-center" data-aos="fade-down">
              <div className="row">
                <div className="col-md-6 mb-3" data-aos="zoom-in">
                  <img
                    src="https://images.pexels.com/photos/3184433/pexels-photo-3184433.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    className="w-100 rounded shadow"
                    alt="About Us"
                  />
                </div>
                <div className="col-md-6 mb-3 second-img" data-aos="zoom-in">
                  <img
                    src="https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    className="w-100 rounded shadow"
                    alt="Our Team"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Our_Services;
