import React, { useState, useEffect } from "react";
import axios from "axios";

function Insurance_nominee() {
  const [formData, setFormData] = useState({
    user_id: "",
    nomineeName: "",
    nomineeRelationship: "",
    dateOfBirth: "",
    gender: "",
    phoneNumber: "",
    adhaarCard: null,
    panCard: null,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false); // To track if it's an update
  const [accordionOpen, setAccordionOpen] = useState(false); // To track accordion state

  // Toggle accordion visibility
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const checkExistingNominee = async () => {
    if (!formData.user_id) {
        setErrorMessage("User ID is required to check nominee.");
        return;
    }

    try {
        const response = await axios.post(
            "https://www.upholic.in/Upholic_Api/insurance_nominee.php",
            { user_id: formData.user_id, fetch: true }
        );

        if (response.data.success && response.data.data) {
            const nomineeData = response.data.data;
            setIsUpdate(true); // Set to update mode

            // Populate form fields with fetched data, ensuring empty values if needed
            setFormData((prevState) => ({
                ...prevState,
                nomineeName: nomineeData.nominee_name || "",
                nomineeRelationship: nomineeData.nominee_relationship || "",
                dateOfBirth: nomineeData.date_of_birth || "",
                gender: nomineeData.gender || "",
                phoneNumber: nomineeData.phone_number || "",
                adhaarCard: nomineeData.adhaar_card || null,
                panCard: nomineeData.pan_card || null,
            }));
        } else {
            setIsUpdate(false); // Set to insert mode
            setFormData((prevState) => ({
                ...prevState,
                nomineeName: "",
                nomineeRelationship: "",
                dateOfBirth: "",
                gender: "",
                phoneNumber: "",
                adhaarCard: null,
                panCard: null,
            }));
        }
    } catch (error) {
        setErrorMessage("Error checking nominee.");
        console.error(error); // Additional error logging
    }
};
  
  useEffect(() => {
    if (formData.user_id) {
      checkExistingNominee();
    }
  }, [formData.user_id]); // Only run when `user_id` changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0], // Assuming one file at a time
    }));
  };

  const validateForm = () => {
    if (
      !formData.user_id ||
      !formData.nomineeName ||
      !formData.nomineeRelationship ||
      !formData.dateOfBirth ||
      !formData.gender ||
      !formData.phoneNumber
    ) {
      setErrorMessage("All fields except Aadhaar and PAN are required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append("user_id", formData.user_id);
    formDataToSend.append("nomineeName", formData.nomineeName);
    formDataToSend.append("nomineeRelationship", formData.nomineeRelationship);
    formDataToSend.append("dateOfBirth", formData.dateOfBirth);
    formDataToSend.append("gender", formData.gender);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    if (formData.adhaarCard) formDataToSend.append("adhaarCard", formData.adhaarCard);
    if (formData.panCard) formDataToSend.append("panCard", formData.panCard);
    formDataToSend.append("isUpdate", isUpdate ? "true" : "false");

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insurance_nominee.php",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        setErrorMessage("");
        setFormData({
          user_id: "",
          nomineeName: "",
          nomineeRelationship: "",
          dateOfBirth: "",
          gender: "",
          phoneNumber: "",
          adhaarCard: null,
          panCard: null,
        }); // Reset form
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("Error submitting the form.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="viewall-container">
      <div className="section">
        <div className="section-title" onClick={toggleAccordion}>
          Insurance Nominee Data
        </div>
        {accordionOpen && (
          <>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <form onSubmit={handleSubmit} className="Pms_insert_user_kyc-form">
              <div className="Pms_insert_user_kyc-row">
                <label>User ID:</label>
                <input
                  type="text"
                  name="user_id"
                  value={formData.user_id}
                  onChange={handleChange}
                />
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Nominee Name:</label>
                <input
                  type="text"
                  name="nomineeName"
                  value={formData.nomineeName}
                  onChange={handleChange}
                />
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Nominee Relationship:</label>
                <select
                  name="nomineeRelationship"
                  value={formData.nomineeRelationship}
                  onChange={handleChange}
                >
                  <option value="">--Select Relationship--</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                </select>
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Date of Birth:</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                />
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Gender:</label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">--Select Gender--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Phone Number:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Adhaar Card:</label>
                <input
                  type="file"
                  name="adhaarCard"
                  onChange={handleFileChange}
                />
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>PAN Card:</label>
                <input type="file" name="panCard" onChange={handleFileChange} />
              </div>

              <div className="investment-row">
                <button type="submit" className="edit-button">
                  {isUpdate ? "Update" : "Insert"}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Insurance_nominee;