import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./Deposite_chart.css";

function Deposite_chart() {
  const [chartData, setChartData] = useState({
    depositAmountData: {},
    monthlyInterestDataChart: {},
    yearlyInterestDataChart: {},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2025"); // Default to selected year
  const [availableYears, setAvailableYears] = useState([]); // List of available years

  useEffect(() => {
    async function fetchDepositData() {
      try {
        // Fetch deposit data for the monthly total amount chart
        const depositAmountResponse = await axios.get(
          `https://www.upholic.in/Upholic_Api/depositeamountchart.php?action=monthtotalamount&selectedYear=${selectedYear}`
        );

        // Fetch yearly interest data
        const yearlyInterestResponse = await axios.get(
          "https://www.upholic.in/Upholic_Api/depositeyearlychart.php?action=yearlyinterest"
        );

        // Fetch monthly interest data
        const monthlyInterestResponse = await axios.get(
          "https://www.upholic.in/Upholic_Api/depositemontlychart.php?action=monthlyinterest"
        );
        console.log("Monthly Interest Data: ", monthlyInterestResponse.data);

        // Check if all responses are arrays
        if (
          Array.isArray(depositAmountResponse.data) &&
          Array.isArray(yearlyInterestResponse.data) &&
          Array.isArray(monthlyInterestResponse.data)
        ) {
          const depositData = depositAmountResponse.data;
          const yearlyInterestData = yearlyInterestResponse.data;
          const monthlyInterestData = monthlyInterestResponse.data;

          // Extract available years from the response (assuming years are in the data)
          const yearsFromData = [
            ...new Set([
              // Union of years from all the datasets
              ...depositData.map((item) => String(item.year)),
              ...yearlyInterestData.map((item) => String(item.year)),
              ...monthlyInterestData.map((item) => String(item.year)),
            ]),
          ];
          setAvailableYears(yearsFromData);

          console.log("Selected Year: ", selectedYear);
          console.log("Before Filtering Deposit Amount Data: ", depositData);

          // Filter data based on selected year
          const filteredDepositData = depositData.filter(
            (item) => String(item.year) === String(selectedYear)
          );
          console.log("Filtered Deposit Data: ", filteredDepositData);

          const filteredYearlyInterestData = yearlyInterestData.filter(
            (item) => String(item.year) === selectedYear
          );

          //   console.log("Selected Year: ", selectedYear);
          //   console.log("Before Filtering Monthly Interest Data: ", monthlyInterestData);

          const filteredMonthlyInterestData = monthlyInterestData.filter(
            (item) => {
              // Ensure 'year' is available and convert it to a string for comparison
              const year = String(item.year || selectedYear); // If 'year' is missing, use selectedYear instead
              // console.log("Item Year: ", year); // Log year to ensure correctness

              // Ensure selectedYear is also a string for a correct comparison
              const selectedYearString = String(selectedYear);

              // Check if the year in the data matches the selected year
              return year === selectedYearString;
            }
          );

          //   console.log("Filtered Monthly Interest Data: ", filteredMonthlyInterestData);

          // Sort the monthly interest data by month
          const monthOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          filteredMonthlyInterestData.sort((a, b) => {
            return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
          });

          // Attempt to extract labels from 'month' and 'year' (adjust if field names are different)
          const depositLabels = filteredDepositData.map((item) => {
            // Extract just the month (first three characters) from 'month_year'
            const fullLabel = `${item.month} ${item.year}`;
            const monthOnly = fullLabel.substring(0, 3); // This grabs the first 3 characters (e.g., 'Jun', 'Aug')
            return monthOnly;
          });

          console.log("Deposit Labels: ", depositLabels);

          // Extract deposit amounts
          const depositAmounts = filteredDepositData.map((item) =>
            parseFloat(item.total_amount)
          );
          console.log("Deposit Amounts: ", depositAmounts);

          // Extract labels and yearly interest amounts for the yearly interest chart
          const yearlyLabels = filteredYearlyInterestData.map(
            (item) => item.year
          );
          const yearlyInterest = filteredYearlyInterestData.map(
            (item) => item.total_yearly_interest
          );

          // Extract monthly interest data and convert string to float
          const monthlyInterest = filteredMonthlyInterestData.map(
            (item) => parseFloat(item.total_monthly_interest.replace(/,/g, "")) // Convert to float
          );

          // Data for the Deposit Amount chart
          const depositAmountData = {
            labels: depositLabels,
            datasets: [
              {
                label: "Deposit Amount",
                data: depositAmounts,
                backgroundColor: "#4e2a84",
                borderColor: "#2e1a53",
                borderWidth: 1,
                barPercentage: 0.9,
                categoryPercentage: 0.5,
              },
            ],
          };

          console.log("Deposit Labels: ", depositLabels);
          console.log("Deposit Amounts: ", depositAmounts);

          console.log("Deposit Amount Data for Chart: ", depositAmountData);

          // Monthly Interest Data for the Monthly Interest chart
          const monthlyInterestDataChart = {
            labels: filteredMonthlyInterestData.map((item) => item.month),
            datasets: [
              {
                label: "Monthly Interest",
                data: monthlyInterest,
                backgroundColor: "#ff7f50",
                borderColor: "#e65c3f",
                borderWidth: 1,
                barPercentage: 0.9,
                categoryPercentage: 0.5,
              },
            ],
          };

          // Yearly Interest Data for the Yearly Interest chart
          const yearlyInterestDataChart = {
            labels: yearlyLabels,
            datasets: [
              {
                label: "Yearly Interest",
                data: yearlyInterest,
                backgroundColor: "#4caf50",
                borderColor: "#388e3c",
                borderWidth: 1,
                barPercentage: 0.3,
                categoryPercentage: 0.5,
              },
            ],
          };

          // Set chart data state
          setChartData({
            depositAmountData,
            monthlyInterestDataChart,
            yearlyInterestDataChart,
          });
        } else {
          throw new Error("Unexpected response format");
        }

        setLoading(false);
      } catch (err) {
        setError("Error fetching data: " + err.message);
        setLoading(false);
      }
    }

    fetchDepositData();
  }, [selectedYear]); // Re-fetch data when selectedYear changes

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  // Chart options configuration
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Month/Year",
          font: {
            size: 16,
          },
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Amount",
          font: {
            size: 16,
          },
        },
      },
    },
  };

  return (
    <div className="deposite-div-border">
      <div className="year-selectorr">
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {availableYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className="deposite-chart-main-container">
        {/* Deposit Amount Chart */}
        <div className="deposite-chart-container">
          <h2>Deposit Amount</h2>
          {chartData.depositAmountData?.labels?.length > 0 ? (
            <Bar
              data={chartData.depositAmountData}
              options={options}
              className="chart"
            />
          ) : (
            <div>No data available for Deposit Amount</div>
          )}
        </div>

        {/* Monthly Interest Chart */}
        <div className="deposite-chart-container">
          <h2>Monthly Interest Paid</h2>
          {chartData.monthlyInterestDataChart.labels?.length ? (
            <Bar
              data={chartData.monthlyInterestDataChart}
              options={options}
              className="chart"
            />
          ) : (
            <div>No data available for Monthly Interest Paid</div>
          )}
        </div>

        {/* Yearly Interest Chart */}
        <div className="deposite-chart-container">
          <h2>Yearly Interest Paid</h2>
          {chartData.yearlyInterestDataChart.labels?.length ? (
            <Bar
              data={chartData.yearlyInterestDataChart}
              options={options}
              className="chart"
            />
          ) : (
            <div>No data available for Yearly Interest Paid</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Deposite_chart;
