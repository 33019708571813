import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import "./Admin_sidebar_links.css";

function Admin_sidebar_links({ onLogout }) {
  const [userType, setUserType] = useState(null); // Track user type (Admin/HR/Subuser)
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = new Cookies();
    const isAdmin = cookies.get("isAdmin");
    const isHR = cookies.get("isHR");
    const isSubuser = cookies.get("isSubuser"); // Check for the subuser cookie

    console.log("isAdmin:", isAdmin); // Debugging log
    console.log("isHR:", isHR); // Debugging log
    console.log("isSubuser:", isSubuser); // Debugging log

    if (isAdmin) {
      setUserType("Admin");
    } else if (isHR) {
      setUserType("HR");
    } else if (isSubuser) {
      setUserType("Subuser"); // Set user type to 'Subuser' if the cookie is found
    } else {
      setUserType("User"); // Default to 'User' if no match
    }
  }, []);

  const handleLogout = async (event) => {
    event.preventDefault();
    const cookies = new Cookies();

    try {
      const response = await axios.get(
        "https://www.upholic.in/Upholic_Api/logout.php?logout=true",
        {
          withCredentials: true,
        }
      );

      if (response.data.message === "Logout successful bye") {
        cookies.remove("username", { path: "/" });
        cookies.remove("id", { path: "/" });
        cookies.remove("isAdmin", { path: "/" });
        cookies.remove("isHR", { path: "/" });
        cookies.remove("isSubuser", { path: "/" }); // Remove the subuser cookie

        if (onLogout && typeof onLogout === "function") {
          onLogout();
        }

        navigate("/login");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="side_side">
      <div className="sidebar-container">
        <ul className="animated-circles">
          {/* Placeholder for animated circles */}
        </ul>
        <nav>
          <ul>
            {userType === "Admin" && (
              <>
                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/admindashboard" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="home-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">Home</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/pms-dashboard" className="icon-link">
                      <ion-icon className="icon-class" name="people"></ion-icon>
                      <span className="icon-tooltip">PMS</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/li-dashboard" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="accessibility-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">LI</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/li-data-insert" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="document-attach-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">LI Data Insert</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/admin-deposit" className="icon-link">
                      <ion-icon className="icon-class" name="wallet"></ion-icon>
                      <span className="icon-tooltip">Deposit</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="card-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">Loan</span>
                    </Link>
                  </li>
                </div>
              </>
            )}

            {(userType === "Admin" || userType === "HR") && (
              <>
                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/hr-dashboard" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="clipboard"
                      ></ion-icon>
                      <span className="icon-tooltip">HR Employee</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/hr-resume" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="book-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">HR Resume</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/admin_document" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="document-text-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">Document</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link className="icon-link" onClick={handleLogout}>
                      <ion-icon
                        className="icon-class"
                        name="log-out"
                      ></ion-icon>
                      <span className="icon-tooltip">Logout</span>
                    </Link>
                  </li>
                </div>
              </>
            )}

            {userType === "Subuser" && (
              <>
                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/admindashboard" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="home-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">Home</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/li-dashboard" className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="accessibility-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">LI</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link to="/admin-deposit" className="icon-link">
                      <ion-icon className="icon-class" name="wallet"></ion-icon>
                      <span className="icon-tooltip">Deposit</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link className="icon-link">
                      <ion-icon
                        className="icon-class"
                        name="card-outline"
                      ></ion-icon>
                      <span className="icon-tooltip">Loan</span>
                    </Link>
                  </li>
                </div>

                <div className="icon-wrapper">
                  <li className="icon-item">
                    <Link className="icon-link" onClick={handleLogout}>
                      <ion-icon
                        className="icon-class"
                        name="log-out"
                      ></ion-icon>
                      <span className="icon-tooltip">Logout</span>
                    </Link>
                  </li>
                </div>
              </>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Admin_sidebar_links;
