import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import ProtectedRoute from "./components/common/ProtectedRoute";
import './App.css';

// Importing all pages
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Sign_up from "./pages/sign-up/Sign_up";
import Dashboard_Main from "./dashboard_/dashborad_main/Dashboard_Main";
import Popup from "./pages/popup/Popup";
import Coming_Soon from "./dashboard_/coming_soon/Coming_Soon";
import Dashboard_Popup from "./dashboard_/dashboard_popup/Dashboard_Popup";
import Pie_Chart from "./dashboard_/pie-chart/Pie_Chart";
import About_Us from "./about/about-us/About_Us";
import Myprofile from "./dashboard_/myprofile/Myprofile";
import EditProfile from "./dashboard_/myeditprofile/EditProfile";
import Admin_dashboard from "./admin/admin_dashboard/Admin_dashboard";
import Admin_Deposite from "./admin/admin_deposite/Admin_Deposite";
import Pms_dashboard from "./admin/pms_dashboard/Pms_dashboard";
import Document_entry from "./admin/document_entry/Document_entry";
import HR_dashboard from "./admin/hr_dashboard/HR_dashboard";
import HR_resume from "./admin/hr_resume_dashboard/HR_resume";
import Life_insurance_dashboard from "./admin/admin_life_insurance/Life_insurance_dashboard";
import Life_insurance_home from "./admin/admin_life_insurance_home/Life_insurance_home";
import Life_Insurance_Home from "./LifeInsurance/life_Insurance_home/Life_Insurance_Home";
import Life_Term_Insurance from "./LifeInsurance/life_Term_Insurance/Life_Term_Insurance";
import Home_Insurance from "./LifeInsurance/Home_Insurance/Home_Insurance";
import Health_Insurance from "./LifeInsurance/Health_Insurance/Health_Insurance";
import Travel_Insurance from "./LifeInsurance/Travel_Insurance/Travel_Insurance";
import Car_Insurance from "./LifeInsurance/Car_Insurance/Car_Insurance";
import Bike_Insurance from "./LifeInsurance/Bike_Insurance/Bike_Insurance";
import Retirement_Plan from "./LifeInsurance/Retirement_Plan/Retirement_Plan";
import Free_oppointment from "./LifeInsurance/Free_Opp/Free_oppointment";

function App() {
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(""); // Store the user role (admin, hr, etc.)
  const [isLoading, setIsLoading] = useState(true);

  // Check authentication state using cookies
  useEffect(() => {
    const username = cookies.get("username");
    const role = cookies.get("role");
    if (username && role) {
      setIsAuthenticated(true);
      setUserRole(role); // Set the user role if present
    }
    setIsLoading(false); // Mark loading complete
  }, []);

  // Handle login
  const handleLogin = (username, role) => {
    setIsAuthenticated(true);
    setUserRole(role); // Save the role after login
    cookies.set("username", username, { path: "/" });
    cookies.set("role", role, { path: "/" }); // Save role in cookies
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About_Us />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Sign_up />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/life-Insurance" element={< Life_Insurance_Home/>} />
        <Route path="/life-term-insurance" element={<Life_Term_Insurance/>}/>
        <Route path="/home-insurance" element={<Home_Insurance/>}/>
        <Route path="/health-insurance" element={<Health_Insurance/>}/>
        <Route path="/travel-insurance" element={<Travel_Insurance/>}/>
        <Route path="/car-insurance" element={<Car_Insurance/>}/>
        <Route path="/bike-insurance" element={<Bike_Insurance/>}/>
        <Route path="/retirement-plan" element={<Retirement_Plan/>}/>
        <Route path="/book-free-appointment" element={<Free_oppointment/>} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<Dashboard_Main />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/comingsoon"
          element={
            <ProtectedRoute
              element={<Coming_Soon />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/dashboard_popup"
          element={
            <ProtectedRoute
              element={<Dashboard_Popup />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/piechart"
          element={
            <ProtectedRoute
              element={<Pie_Chart />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute
              element={<Myprofile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/editprofile"
          element={
            <ProtectedRoute
              element={<EditProfile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute
              element={<Admin_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/admin-deposit"
          element={
            <ProtectedRoute
              element={<Admin_Deposite />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/pms-dashboard"
          element={
            <ProtectedRoute
              element={<Pms_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/li-dashboard"
          element={
            <ProtectedRoute
              element={<Life_insurance_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/li-data-insert"
          element={
            <ProtectedRoute
              element={<Life_insurance_home />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/admin_document"
          element={
            <ProtectedRoute
              element={<Document_entry />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/hr-dashboard"
          element={
            <ProtectedRoute
              element={<HR_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/hr-resume"
          element={
            <ProtectedRoute
              element={<HR_resume />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
