import React, { useState, useEffect, useRef } from 'react';
import './Life_Term_Insurance.css'; // Updated CSS import
import Regular_Term from '../../assets/images/Regular_Term_Insurance.avif';
import Return_Plans from '../../assets/images/Return_of_Premium_Term_Plans.webp';
import No_Cost_Insurance from '../../assets/images/No_Cost_Term_Insurance.jpg';
import whole_life_policy from '../../assets/images/Whole_Life_Insurance_Policy.jpg';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import life_term_insurances from '../../assets/images/insurancehero.jpg'; // Add correct path to your image
import { Link } from 'react-router-dom';

function Life_Term_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: Regular_Term,
            title: 'Regular Term Insurance',
            description: 'Provides coverage for a fixed term with a constant death benefit and affordable premiums.'
        },
        {
            img: Return_Plans,
            title: 'Return of Premium Term Plans',
            description: 'Offers a refund of premiums paid if the policyholder outlives the term, ensuring no financial loss.'
        },
        {
            img: No_Cost_Insurance,
            title: 'No-Cost Term Insurance',
            description: 'Provides flexibility to exit or convert the policy at no additional cost during the term.'
        },
        {
            img: whole_life_policy,
            title: 'Whole Life Insurance Policy',
            description: 'Offers lifelong coverage with a guaranteed death benefit and potential cash value accumulation.'
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="new-header">
                <h1 className="new-title">Life Term Insurance</h1>
                <p className="new-subtitle">Affordable and Flexible Protection for Your Family</p>

                <div className="new-intro-section">
                    <p className="new-intro-text">
                        Life Term Insurance offers temporary coverage for a fixed period at an affordable rate. If the policyholder survives the term, there is no payout.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Term life insurance is more affordable than permanent insurance policies.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Clear terms with no investment component, just straightforward coverage.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Choose from a variety of term lengths to meet your financial needs.</p>
                        </div>
                    </div>
                </div>

                <div className="carousell-new-container">
                    <h2 className="home_insurance-section-title">Types of Life Term Insurance</h2>
                    <div className="carousell-new">
                        <div
                            className="carousell-new__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <div
                                    key={index}
                                    className="carousell-new__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell-new__img-container">
                                        <img className="carousell-new__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell-new__content">
                                        <h3 className="carousell-new__title">{item.title}</h3>
                                        <p className="carousell-new__para">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="new-benefits-section">
                    <h2 className="new-section-title">Benefits of Life Term Insurance</h2>
                    <div className='new-benefits-inner-section'>
                        <div>
                            <img src={life_term_insurances} alt="Benefits of Life Term Insurance" />
                        </div>
                        <div>
                            <ul className="new-benefits-list">
                                <li>Term life insurance typically offers significantly more affordable premiums when compared to permanent life insurance policies, making it a more cost-effective option for many families.</li>
                                <li>The terms of term life insurance are straightforward and easy to understand, with no complex investment components involved, ensuring transparency for policyholders.</li>
                                <li>This type of insurance provides vital coverage for a predetermined period, offering peace of mind during critical life stages or when you have significant financial obligations, such as a mortgage or children's education.</li>
                                <li>With flexible term lengths available, you can choose a policy that aligns with your specific financial needs and long-term goals, ensuring adequate protection at the right time.</li>
                                <li>Depending on the regulations in your region, term life insurance may offer various tax benefits, which can help reduce your overall tax burden while securing financial protection for your loved ones.</li>
                                <li>For more benefits Book our free Appointment.</li>
                            </ul>
                            <div className="new-cta-section">
                                <Link to='/book-free-appointment' className="new-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Life_Term_Insurance;