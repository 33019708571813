import React, { useEffect } from "react";
import "./Fact.scss";
import AOS from "aos";
import "aos/dist/aos.css";

function Fact() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <>
      <div className="free_try_main_div">
        <div className="free_try_container" data-aos="fade-right">
          <h1 className="free_try_title" data-aos="fade-down">
            Book your appointment
          </h1>
          <p className="free_try_para" data-aos="fade-up">
            Focus on what matters most. We're committed to providing you exceptional value.
          </p>
          <div className="free_try_form" data-aos="zoom-in">
            <input
              className="free_try_input"
              type="text"
              placeholder="Your name"
            />
            <input
              className="free_try_input"
              type="tel"
              placeholder="Your phone number"
            />
            <button className="free_try_button">Try it Now</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fact;
