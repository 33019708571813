import React, { useState, useEffect } from "react";
import axios from "axios";

function EmployeeKYC() {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeData, setEmployeeData] = useState({});
  const [files, setFiles] = useState({
    photo: null,
    aadhar_card: null,
    pan_card: null,
    education_certificate: null,
    bank_check: null,
  });

  // Fetch employee details when employeeId changes
  useEffect(() => {
    if (employeeId.trim() === "") {
      // Reset employeeData and files if employeeId is cleared
      setEmployeeData({});
      setFiles({
        photo: null,
        aadhar_card: null,
        pan_card: null,
        education_certificate: null,
        bank_check: null,
      });
      setMessage("");
      return;
    }

    const fetchEmployeeDetails = async (id) => {
      try {
        const response = await axios.post(
          "https://www.upholic.in/Upholic_Api/employee_kyc_fetch.php",
          new URLSearchParams({ employee_id: id })
        );

        if (response.data.success) {
          setEmployeeData(response.data.employee);
          setMessage("");
        } else {
          setEmployeeData({});
          setMessage(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching employee details:", error);
        setMessage("Failed to fetch employee details. Please try again.");
      }
    };

    fetchEmployeeDetails(employeeId);
  }, [employeeId]);

  const toggleAccordion = () => setAccordionOpen(!accordionOpen);

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    setFiles((prev) => ({ ...prev, [field]: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!employeeId) {
      setMessage("Employee ID is required");
      return;
    }

    const formData = new FormData();
    formData.append("employee_id", employeeId);
    Object.keys(files).forEach((key) => {
      if (files[key]) formData.append(key, files[key]);
    });

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/employee_kyc_upload.php",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log(response);

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        setMessage("");
      } else {
        setSuccessMessage("");
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      setSuccessMessage("");
      setMessage("Failed to upload documents. Please try again.");
    }
  };

  const fileFields = [
    { label: "Photo", key: "photo" },
    { label: "Aadhar Card", key: "aadhar_card" },
    { label: "Pan Card", key: "pan_card" },
    { label: "Education Certificate", key: "education_certificate" },
    { label: "Bank cheque", key: "bank_check" },
  ];

  const fileUrl = (fileName) =>
    fileName
      ? `https://www.upholic.in/Upholic_Api/uploads/employee_document_kyc/${fileName}`
      : null;

  return (
    <div className="viewall-container">
      <div className="section">
        <div className="section-title" onClick={toggleAccordion}>
          Employee KYC
        </div>
        {accordionOpen && (
          <>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {message && <div className="error-message">{message}</div>}

            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="Pms_insert_user_kyc-form"
            >
              {/* Employee ID Field */}
              <div className="Pms_insert_user_kyc-row">
                <label>Employee ID:</label>
                <input
                  type="text"
                  name="employee_id"
                  value={employeeId}
                  onChange={(e) => {
                    setEmployeeId(e.target.value);
                  }}
                  required
                />
              </div>

              {/* File Uploads */}
              {fileFields.map(({ label, key }) => (
                <div key={key} className="Pms_insert_user_kyc-row">
                  <label>{label}:</label>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <input
                      type="file"
                      name={key}
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleFileChange(e, key)}
                    />
                    {employeeData[key] ? (
                      <a
                        href={fileUrl(employeeData[key])}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "0.9rem", color: "blue" }}
                      >
                        {employeeData[key]}
                      </a>
                    ) : (
                      <span style={{ fontSize: "0.9rem", color: "gray" }}>
                        No file uploaded
                      </span>
                    )}
                  </div>
                </div>
              ))}

              <div className="investment-row">
                <button type="submit" className="edit-button">
                  Insert
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default EmployeeKYC;
