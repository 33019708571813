import React from 'react';
import './Free_oppointment.css';

function Free_oppointment() {
    return (
        <>
            <div className="appointment-main">
                <div className="appointment-container">
                    <h1 className="appointment-title">
                        Book your appointment
                    </h1>
                    <p className="appointment-description">
                        Focus on what matters most. We're committed to providing you exceptional value.
                    </p>
                    <div className="appointment-form">
                        <input
                            className="appointment-input"
                            type="text"
                            placeholder="Your name"
                        />
                        <input
                            className="appointment-input"
                            type="tel"
                            placeholder="Your phone number"
                        />
                        <button className="appointment-button">Try it Now</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Free_oppointment