import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Life_Insurance_Type.scss";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

function Life_Insurance_Type() {
  const insurancePlans = [
    { id: "europe1", name: "Life Term Insurance", path: "/life-term-insurance" },
    // { id: "europe2", name: "Family Health Insurance", path: "/family-health-insurance" },
    { id: "europe4", name: "Health Insurance", path: "/health-insurance" },
    { id: "europe6", name: "Home Insurance", path: "/home-insurance" },
    { id: "europe8", name: "Retirement Plan", path: "/retirement-plan" },
    { id: "europe7", name: "Travel Insurance", path: "/travel-insurance" },
    { id: "europe3", name: "Car Insurance", path: "/car-insurance" },
    { id: "europe5", name: "Bike Insurance", path: "/bike-insurance" },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, 
    });
  }, []);

  return (
    <div className="life-insurance-dashboard">
      <h2 className="life-insurance-heading">Top Insurance Plans</h2>
      <div className="life-insurance-container">
        {insurancePlans.map((plan) => (
          <Link
            key={plan.id}
            to={plan.path} // Dynamically link to the insurance page
            className="life-insurance-card"
            id={plan.id}
            // data-aos="fade-up" // Apply fade-up animation on scroll
          >
            <div className="life-insurance-overlay"></div>
            <h3 className="life-insurance-title">{plan.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Life_Insurance_Type;
