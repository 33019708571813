import React, { useEffect } from "react";
import "./Features.scss";
import gsap from "gsap";
import AOS from 'aos';
import { Flip, CustomEase } from "gsap/all";
import fast_execution from "../../assets/images/fast-execution.jpg";
import financial_security from "../../assets/images/Financial_Security.avif";
import innovative_trading from "../../assets/images/Innovative_Trading.avif";
import smart_investment from "../../assets/images/smart_investment.jpg";

function Features() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Allow animation to repeat every time on scroll
      mirror: true, // Repeat animation when scrolling up
    });
  }, []);

  useEffect(() => {
    gsap.registerPlugin(CustomEase, Flip);

    CustomEase.create("osmo-ease", "0.625, 0.05, 0, 1");

    gsap.defaults({
      ease: "osmo-ease",
      duration: 0.8,
    });

    // Flip Buttons Initialization
    const initFlipButtons = () => {
      let wrappers = document.querySelectorAll('[data-flip-button="wrap"]');

      wrappers.forEach((wrapper) => {
        let buttons = wrapper.querySelectorAll('[data-flip-button="button"]');
        let bg = wrapper.querySelector('[data-flip-button="bg"]');

        buttons.forEach(function (button) {
          // Handle mouse enter
          button.addEventListener("mouseenter", function () {
            const state = Flip.getState(bg);
            this.appendChild(bg);
            Flip.from(state, {
              duration: 0.4,
            });
          });

          // Handle focus for keyboard navigation
          button.addEventListener("focus", function () {
            const state = Flip.getState(bg);
            this.appendChild(bg);
            Flip.from(state, {
              duration: 0.4,
            });
          });

          // Handle mouse leave
          button.addEventListener("mouseleave", function () {
            const state = Flip.getState(bg);
            const activeLink = wrapper.querySelector(".active");
            activeLink.appendChild(bg);
            Flip.from(state, {
              duration: 0.4,
            });
          });

          // Handle blur to reset background for keyboard navigation
          button.addEventListener("blur", function () {
            const state = Flip.getState(bg);
            const activeLink = wrapper.querySelector(".active");
            activeLink.appendChild(bg);
            Flip.from(state, {
              duration: 0.4,
            });
          });
        });
      });
    };

    // Tabs Initialization
    const initTabSystem = () => {
      let wrappers = document.querySelectorAll('[data-tabs="wrapper"]');

      wrappers.forEach((wrapper) => {
        let nav = wrapper.querySelector('[data-tabs="nav"]');
        let buttons = nav.querySelectorAll('[data-tabs="button"]');
        let contentWrap = wrapper.querySelector('[data-tabs="content-wrap"]');
        let contentItems = contentWrap.querySelectorAll(
          '[data-tabs="content-item"]'
        );
        let visualWrap = wrapper.querySelector('[data-tabs="visual-wrap"]');
        let visualItems = visualWrap.querySelectorAll(
          '[data-tabs="visual-item"]'
        );

        let activeButton = buttons[0];
        let activeContent = contentItems[0];
        let activeVisual = visualItems[0];
        let isAnimating = false;

        function switchTab(index, initial = false) {
          if (!initial && (isAnimating || buttons[index] === activeButton))
            return; // ignore click if the clicked button is already active
          isAnimating = true; // keep track of whether or not one is moving, to prevent overlap

          const outgoingContent = activeContent;
          const incomingContent = contentItems[index];
          const outgoingVisual = activeVisual;
          const incomingVisual = visualItems[index];

          let outgoingLines =
            outgoingContent.querySelectorAll("[data-tabs-fade]") || [];
          let incomingLines =
            incomingContent.querySelectorAll("[data-tabs-fade]");

          const timeline = gsap.timeline({
            defaults: {
              ease: "power3.inOut",
            },
            onComplete: () => {
              if (!initial) {
                outgoingContent && outgoingContent.classList.remove("active");
                outgoingVisual && outgoingVisual.classList.remove("active");
              }
              activeContent = incomingContent;
              activeVisual = incomingVisual;
              isAnimating = false;
            },
          });

          incomingContent.classList.add("active");
          incomingVisual.classList.add("active");

          timeline
            .to(outgoingLines, { y: "-2em", autoAlpha: 0 }, 0)
            .to(outgoingVisual, { autoAlpha: 0, xPercent: 3 }, 0)
            .fromTo(
              incomingLines,
              { y: "2em", autoAlpha: 0 },
              { y: "0em", autoAlpha: 1, stagger: 0.075 },
              0.4
            )
            .fromTo(
              incomingVisual,
              { autoAlpha: 0, xPercent: 3 },
              { autoAlpha: 1, xPercent: 0 },
              "<"
            );

          activeButton && activeButton.classList.remove("active");
          buttons[index].classList.add("active");
          activeButton = buttons[index];
        }

        switchTab(0, true); // on page load

        buttons.forEach((button, i) => {
          button.addEventListener("click", () => switchTab(i));
        });

        contentItems[0].classList.add("active");
        visualItems[0].classList.add("active");
        buttons[0].classList.add("active");
      });
    };

    initTabSystem();
    initFlipButtons();
  }, []); // Empty array means this runs once when the component is mounted

  return (
    <>
      <section className="tab-cloneable">
        <div data-tabs="wrapper" className="tab-layout">
          <div className="tab-layout-col" data-aos="fade-right">
            <div className="tab-layout-container">
              <div className="tab-container">
                <div className="tab-container-top">
                  <h1 className="tab-layout-heading" data-aos="fade-up">
                    Investing in the Future:
                    <br />
                    Trends That Will Shape Tomorrow
                  </h1>
                  <div
                    data-flip-button="wrap"
                    data-tabs="nav"
                    className="tab-filter-bar"
                    data-aos="fade-up"
                  >
                    <button
                      data-tabs="button"
                      data-flip-button="button"
                      className="tab-filter-button active"
                    >
                      <div className="tab-filter-button__p">Life Insurance</div>
                      <div
                        data-flip-button="bg"
                        className="tab-button__bg"
                      ></div>
                    </button>
                    <button
                      data-tabs="button"
                      data-flip-button="button"
                      className="tab-filter-button"
                    >
                      <div className="tab-filter-button__p">Financial Planning</div>
                    </button>
                    <button
                      data-tabs="button"
                      data-flip-button="button"
                      className="tab-filter-button"
                    >
                      <div className="tab-filter-button__p">PMS</div>
                    </button>
                    {/* New button for Finance */}
                    <button
                      data-tabs="button"
                      data-flip-button="button"
                      className="tab-filter-button"
                    >
                      <div className="tab-filter-button__p">Loan</div>
                    </button>
                  </div>
                </div>
                <div className="tab-container-bottom" data-aos="fade-up">
                  <div data-tabs="content-wrap" className="tab-content-wrap">
                    <div
                      data-tabs="content-item"
                      className="tab-content-item active"
                    >
                      <h2 data-tabs-fade="" className="tab-content__heading">
                        Life Insurance
                      </h2>
                      <p data-tabs-fade="" className="tab-content-p opacity--80">
                      Life insurance provides financial security for loved ones 
                      and future needs.
                      </p>
                    </div>
                    <div data-tabs="content-item" className="tab-content-item" data-aos="fade-left">
                      <h2 data-tabs-fade="" className="tab-content__heading">
                      Financial Planning
                      </h2>
                      <p data-tabs-fade="" className="tab-content-p opacity--80">
                      Managing finances for long-term security and financial goals.
                      </p>
                    </div>
                    <div data-tabs="content-item" className="tab-content-item" data-aos="fade-left">
                      <h2 data-tabs-fade="" className="tab-content__heading">
                        PMS
                      </h2>
                      <p data-tabs-fade="" className="tab-content-p opacity--80">
                      Personalized investment strategies to manage and grow your wealth efficiently.
                      </p>
                    </div>
                    {/* New content for Finance */}
                    <div data-tabs="content-item" className="tab-content-item" data-aos="fade-left">
                      <h2 data-tabs-fade="" className="tab-content__heading">
                        Loan
                      </h2>
                      <p data-tabs-fade="" className="tab-content-p opacity--80">
                      Offering flexible loan solutions to meet your financial needs with competitive rates.
                      </p>
                    </div>
                  </div>
                  <a
                    id="form-button"
                    // href="#"
                    className="tab-content__button w-inline-block" data-aos="zoom-in"
                  >
                    <p className="tab-content-p">Shape your tomorrow</p>
                    <div className="content-button__bg"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-layout-col" data-aos="fade-down">
            <div data-tabs="visual-wrap" className="tab-visual-wrap">
              <div data-tabs="visual-item" className="tab-visual-item active">
                <img
                  src={fast_execution}
                  loading="lazy"
                  className="tab-image"
                  data-aos="fade-in"
                />
              </div>
              <div data-tabs="visual-item" className="tab-visual-item">
                <img
                  src={financial_security}
                  loading="lazy"
                  className="tab-image"
                  data-aos="fade-in"
                />
              </div>
              <div data-tabs="visual-item" className="tab-visual-item">
                <img
                  src={innovative_trading}
                  loading="lazy"
                  className="tab-image"
                  data-aos="fade-in"
                />
              </div>
              {/* New image for Finance */}
              <div data-tabs="visual-item" className="tab-visual-item">
                <img
                  src={smart_investment}
                  loading="lazy"
                  className="tab-image"
                  data-aos="fade-in"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;
