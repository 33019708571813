import React, { useState, useEffect } from "react";
import axios from "axios";

function Life_Insurance_kyc() {
  const [formData, setFormData] = useState({
    user_id: "",
    full_name: "",
    date_of_birth: "",
    phone_number: "",
    annual_income: "",
    occupation: "",
    email: "",
    education: "",
    marital_status: "",
    state: "",
    city: "",
    pin_code: "",
    gender: "",
    adhaar_card: null,
    pan_card: null,
    nationality: "",
    tobacco_nicotine_alcohol: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen); // Toggle accordion state
  };

  useEffect(() => {
    if (formData.user_id || formData.phone_number) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://www.upholic.in/Upholic_Api/insurance_kyc.php?user_id=${
              formData.user_id || ""
            }&phone_number=${formData.phone_number || ""}`
          );
          if (response.data.status === "success") {
            setFormData(response.data.data);
            setIsUpdate(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [formData.user_id, formData.phone_number]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    for (const key in formData) {
      if (formData[key]) {
        data.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insurance_kyc.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        setSuccessMessage(response.data.message);
        setErrorMessage("");
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            Insurance Kyc Data
          </div>
          {accordionOpen && (
            <>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <form
                className="Pms_insert_user_kyc-form"
                onSubmit={handleSubmit}
              >
                <div className="Pms_insert_user_kyc-row">
                  <label>User ID:</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Full Name:</label>
                  <input
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Date of Birth:</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Phone Number:</label>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Annual Income:</label>
                  <input
                    type="text"
                    name="annual_income"
                    value={formData.annual_income}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Occupation:</label>
                  <select
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleChange}
                  >
                    <option value="">--Select Occupation--</option>
                    <option value="self_employed">Self-Employed</option>
                    <option value="professional">Professional</option>
                    <option value="entrepreneur">Entrepreneur</option>
                    <option value="freelancer">Freelancer</option>
                    <option value="student">Student</option>
                    <option value="unemployed">Unemployed</option>
                    <option value="retired">Retired</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>E-mail:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Education:</label>
                  <select
                    name="education"
                    value={formData.education}
                    onChange={handleChange}
                  >
                    <option value="">--Select Education--</option>
                    <option value="high_school">High School</option>
                    <option value="bachelor">Bachelor's Degree</option>
                    <option value="master">Master's Degree</option>
                    <option value="doctorate">Doctorate</option>
                    <option value="associate">Associate's Degree</option>
                    <option value="diploma">Diploma</option>
                    <option value="certification">Certification</option>
                    <option value="none">None</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Marital Status:</label>
                  <select
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleChange}
                  >
                    <option value="">--Select Marital Status--</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>State:</label>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">--Select State--</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Puducherry">Puducherry</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>City:</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>PIN Code:</label>
                  <input
                    type="text"
                    name="pin_code"
                    value={formData.pin_code}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Gender:</label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="">--Select Gender--</option>
                    <option value="male">male</option>
                    <option value="female">female</option>
                    <option value="other">other</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Adhaar Card:</label>
                  <input
                    type="file"
                    name="adhaar_card"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png"
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>PAN Card:</label>
                  <input
                    type="file"
                    name="pan_card"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png"
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Nationality:</label>
                  <input
                    type="text"
                    name="nationality"
                    value={formData.nationality}
                    onChange={handleChange}
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Tobacco/Nicotine/Alcohol:</label>
                  <select
                    name="tobacco_nicotine_alcohol"
                    value={formData.tobacco_nicotine_alcohol}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    {isUpdate ? "Update" : "Insert"}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Life_Insurance_kyc;
