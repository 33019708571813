import React, {useEffect, useState, useRef} from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import bike_insurance from '../../assets/images/bike_insurance.jpg';
import bike_third from '../../assets/images/Third_Party_Bike_Insurance.avif';
import com_bike_insurance from '../../assets/images/Comprehensive_Bike_Insurance.avif';
import own_damage from '../../assets/images/Own_Damage_Bike_Insurance.avif';
import peronal_accident from '../../assets/images/Personal_Accident_Cover_for_Riders.avif';
import { Link } from 'react-router-dom';

function Bike_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: bike_third,
            title: 'Third-Party Bike Insurance',
            description: 'Covers liabilities for damages or injuries caused to third parties in an accident involving your bike.'
        },
        {
            img: com_bike_insurance,
            title: 'Comprehensive Bike Insurance',
            description: 'Provides all-around coverage for damages to your bike, third-party liabilities, theft, and natural calamities.'
        },
        {
            img: own_damage,
            title: 'Own Damage Bike Insurance',
            description: 'Protects your bike from damages due to accidents, fire, theft, and other unforeseen events.'
        },
        {
            img: peronal_accident,
            title: 'Personal Accident Cover for Riders',
            description: 'Offers compensation for injuries or fatalities resulting from bike accidents.'
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <h1 className="home_insurance-main-title">Bike Insurance</h1>
                <p className="home_insurance-subtitle">Protect Your Bike with Comprehensive Insurance Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Bike insurance provides financial protection for your two-wheeler, covering damages, theft, and third-party liabilities to ensure you ride with confidence.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Bike insurance plans are cost-effective and provide essential coverage for a worry-free ride.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Easy-to-understand policies that ensure clarity in coverage and the claims process for riders.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Customize your bike insurance policy with add-ons and tailored coverage options to suit your needs.</p>
                        </div>
                    </div>
                </div>


                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Bike Insurance</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <div
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title">Benefits of Bike Insurance</h2>
                    <div className="home_insurance-benefits-inner-section">
                        <div>
                            <img src={bike_insurance} alt="Benefits of Bike Insurance" />
                        </div>
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Comprehensive protection against damages, theft, and third-party liabilities for your bike.</li>
                                <li>Covers repair costs, medical expenses, and legal liabilities arising from accidents.</li>
                                <li>Ensures peace of mind while riding, knowing you're financially protected against unforeseen events.</li>
                                <li>Provides flexible coverage options and add-ons, like zero depreciation or roadside assistance.</li>
                                <li>Offers 24/7 customer support to assist you with claims and emergencies.</li>
                                <li>Discover more benefits by booking a free appointment with our team of experts to choose the right bike insurance plan for you.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bike_Insurance;
