import React, {useEffect} from "react";
import "./Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: false, // Allow animation to repeat every time on scroll
      mirror: true, // Repeat animation when scrolling up
    });
  }, []);

  return (
  <>
       <div className="footer-container">
      <div className="footer-inner">
        <div className="footer-row">
          <div className="footer-column" data-aos="fade-up">
            <h4 className="footer-heading">Our Office</h4>
            <p className="footer-info">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" />
              FA-05/9, Vikas Business Centre, Lake City Mall, 1st floor,
              Kapurbawdi, Thane(W), 400601
            </p>
            <p className="footer-info">
              <FontAwesomeIcon icon={faPhoneAlt} className="footer-icon" />
              022-44511316
            </p>
            <p className="footer-info">
              <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
              customer.service@upholic.in
            </p>
            <div className="footer-social-icons">
              <a className="footer-social-icon" data-aos="fade-up" data-aos-delay="100">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a className="footer-social-icon" data-aos="fade-up" data-aos-delay="200">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a className="footer-social-icon" data-aos="fade-up" data-aos-delay="300">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a className="footer-social-icon" data-aos="fade-up" data-aos-delay="400">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
          <div className="footer-column" data-aos="fade-up" data-aos-delay="500">
            <h4 className="footer-heading">Services</h4>
            <Link className="footer-link" to="/comingsoon">
              Financial planning
            </Link>
            <Link className="footer-link" to="/popup">
              PMS Services
            </Link>
            <Link className="footer-link" to="/comingsoon">
              Life Insurance
            </Link>
            <Link className="footer-link" to="/comingsoon">
              Loans
            </Link>
          </div>
          <div className="footer-column" data-aos="fade-up" data-aos-delay="600">
            <h4 className="footer-heading">Quick Links</h4>
            <Link className="footer-link" to="/about">
              About Us
            </Link>
            <Link className="footer-link" to="/popup">
              Our Services
            </Link>
            <Link className="footer-link" to="/comingsoon">
              Terms & Condition
            </Link>
            <Link className="footer-link" to="/comingsoon">
              Support
            </Link>
          </div>
          <div className="footer-column" data-aos="fade-up" data-aos-delay="700">
            <h4 className="footer-heading">Stay Connected</h4>
            <div className="footer-email-signup">
              <input
                className="footer-email-input"
                type="email"
                placeholder="Your email"
              />
              <button className="footer-signup-button">Sign Up</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Footer;
